import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Img from "gatsby-image"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class IndexPage extends React.Component {

	componentDidMount = () => {

		if (!window.innerWidth < 767) {
			boxSizes();	
		}

		window.addEventListener('resize', function(){
			if (!window.innerWidth < 767) {
				boxSizes();
			}
		});

	}

    render(props) {

		const {data} = this.props;
		const pathname = this.props.location.pathname;
		const page = this.props.data.wordpressPage;

		return (

			<Layout additionalClass="homepage" pageName={page.title}>

				<SEO title={page.yoast.title} description={page.yoast.metadesc} pathname={pathname} />

				<div className="homepage-swirl"></div>

				<div className="hero">

					<div className="container">

						{page.acf.heading ? 
							<h1 dangerouslySetInnerHTML={{ __html: page.acf.heading }} />
						: 
							<h1 dangerouslySetInnerHTML={{ __html: page.title }} />
						}

						{page.acf.subheading ? 
							<h2 dangerouslySetInnerHTML={{ __html: page.acf.subheading }} />
						: null}
								
					</div>

					<Link className="arrow-wrap" to="/#content">
						<span id="scroll" className="bounce scroll icon-chevron-thin-down"></span>
					</Link>

				</div>

				<div id="content" className="content">

					{page.acf.portfolio_selector ? 

						<div id="work" className="grid">

							{page.acf.portfolio_selector.map((portfolio, index) =>

									<Link key={index} to={`/work/${portfolio.post_name}`} className="item">
									
										{portfolio.acf.featured_image ?

											<Img style={{ position: 'static', height: '100%' }} fluid={portfolio.acf.featured_image.localFile.childImageSharp.fluid} />
										: 
											<div className="bg">Item</div>
										}

										<div className={portfolio.acf.dark_light_text === `dark` ? `dark-text portfolio-info` : `portfolio-info`}>

											<h2 dangerouslySetInnerHTML={{ __html: portfolio.post_title }} />

											{portfolio.acf.featured_text ?
												<div className="extra-info">
													<div dangerouslySetInnerHTML={{ __html: portfolio.acf.featured_text }} />
												</div>
											: null }

											<div className="tags">
												{portfolio.acf.categories &&
													portfolio.acf.categories.map((node, index) =>
														<div key={index} className="tag" dangerouslySetInnerHTML={{ __html: node.name }} />
												)}
											</div>

										</div>

									</Link>

							)}
							
						</div>
					
					: null}
					
					{data.allWordpressWpRedderTestimonial ? 

						<div className="testimonials">

							<div className="container">

								<Carousel emulateTouch={true} showThumbs={false} showStatus={false} infiniteLoop={true} showArrows={false} autoPlay={true} interval={7500}>

									{data.allWordpressWpRedderTestimonial.edges.map(({ node }) => (
										<div key={node} className="item">
											<div className="text" dangerouslySetInnerHTML={{ __html: node.content }} />
											<div className="info">
												<div className="name">{node.acf.name}</div>
												<div className="pipe">|</div>
												<div className="company" dangerouslySetInnerHTML={{ __html: node.acf.company }} />
											</div>
										</div>
									))}

								</Carousel>

							</div>
						
						</div>
					
					: null }

				</div>

			</Layout>

		)
	}
	  
}

export default IndexPage

export const pageQuery = graphql`
	query {
		wordpressPage(slug: {eq: "home"}) {
			title
			content
			acf {
				heading
				subheading
				portfolio_selector {
					post_name
					post_title
					acf {
						dark_light_text
						description
						featured_text
						featured_image {
							localFile {
								childImageSharp {
									fluid(maxWidth: 3000) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
					acf {
						categories {
							slug
							name
						}
					}
				}
			}
			yoast{
				title
				metadesc
			}
		},
		allWordpressWpRedderTestimonial {
			edges {
				node {
					acf{
						name
						company
					}
				content
				}
			}
		}
    }
`

function boxSizes() {

	if (document.querySelector('.grid')) {
		
		var c = document.querySelector(".grid").childNodes;
		c[0].classList.add("small");
		c[1].classList.add("small");
		c[2].classList.add("small");
		c[3].classList.add("tall");
		c[4].classList.add("long");
		c[5].classList.add("small");
		c[6].classList.add("small");
		c[7].classList.add("long");
		c[8].classList.add("small");

		var small = document.querySelectorAll('.item.small');
		var long = document.querySelectorAll('.item.long');
		var tall = document.querySelectorAll('.item.tall');

		small.forEach(function(square) {

			square.style.height = (square.offsetWidth - 40) + "px";

			long.forEach(function(longHeight) {
				longHeight.style.height = square.clientHeight + "px";
			});

			if (window.innerWidth > 992) {

				tall.forEach(function(tallHeight) {
					tallHeight.style.height = (square.clientHeight * 2 + 15) + "px";
				});

			}

			else {
				tall.forEach(function(tallHeight) {
					tallHeight.style.height = (square.offsetWidth - 40) + "px";
				});
			}

		});

	}

}